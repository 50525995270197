<template>
  <div class="" style="min-height: 75vh">
    <div class="row" style="background: #f5f6f6; padding: 0px 10px">
      <div class="col-12 pl-0 col-md-5 py-3">
        <div
          class="card"
          style="
            border: 1px solid transparent;
            border-radius: 8px;
            height: 65vh;
            overflow: auto;
          "
        >
          <div class="card-body">
            <div class="quizell-step3-sidebar-title">Design Customization</div>
            <div class="quizell-step3-sidebar-description">
              Choose a parameters that align with your brand or the mood of the
              quiz.
            </div>
            <div class="quizell-ai-tools mt-3">
              <!-- Colors -->
              <Collapse id="colors" class="mb-2">
                <template v-slot:title>
                  <span class="mr-2"
                    ><svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.76667 16.3333L4.16667 14.7333L10.8833 8.00001L12.5 9.61667M17.2583 5.19167L15.3083 3.24167C15 2.91667 14.4583 2.91667 14.1333 3.24167L11.5333 5.84167L9.925 4.25001L8.75 5.42501L9.93333 6.60834L2.5 14.0417V18H6.45833L13.8917 10.5667L15.075 11.75L16.25 10.575L14.65 8.97501L17.25 6.37501C17.5833 6.04167 17.5833 5.50001 17.2583 5.19167Z"
                        fill="#18191C"
                      />
                    </svg>
                  </span>
                  Colors
                </template>
                <template v-slot:collapse>
                  <div
                    class="w-100 d-flex align-items-start mt-2"
                    style="overflow-x: scroll; gap: 10px"
                  >
                    <div
                      style="
                        min-width: 120px;
                        min-height: 120px;
                        position: relative;
                        cursor: pointer;
                      "
                      v-if="false"
                    >
                      <div
                        class=""
                        style="position: absolute; right: 5px; top: 2px"
                      >
                        <svg
                          v-if="selectedColorView == 'websiteColor'"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M10.0001 16.6665C8.23197 16.6665 6.53628 15.9641 5.28604 14.7139C4.03579 13.4636 3.33341 11.7679 3.33341 9.99984C3.33341 8.23173 4.03579 6.53603 5.28604 5.28579C6.53628 4.03555 8.23197 3.33317 10.0001 3.33317C11.7682 3.33317 13.4639 4.03555 14.7141 5.28579C15.9644 6.53603 16.6667 8.23173 16.6667 9.99984C16.6667 11.7679 15.9644 13.4636 14.7141 14.7139C13.4639 15.9641 11.7682 16.6665 10.0001 16.6665ZM10.0001 1.6665C8.90573 1.6665 7.8221 1.88205 6.81105 2.30084C5.80001 2.71963 4.88135 3.33346 4.10752 4.10728C2.54472 5.67008 1.66675 7.7897 1.66675 9.99984C1.66675 12.21 2.54472 14.3296 4.10752 15.8924C4.88135 16.6662 5.80001 17.28 6.81105 17.6988C7.8221 18.1176 8.90573 18.3332 10.0001 18.3332C12.2102 18.3332 14.3298 17.4552 15.8926 15.8924C17.4554 14.3296 18.3334 12.21 18.3334 9.99984C18.3334 8.90549 18.1179 7.82185 17.6991 6.81081C17.2803 5.79976 16.6665 4.8811 15.8926 4.10728C15.1188 3.33346 14.2002 2.71963 13.1891 2.30084C12.1781 1.88205 11.0944 1.6665 10.0001 1.6665ZM10.0001 5.83317C8.89501 5.83317 7.8352 6.27216 7.0538 7.05356C6.2724 7.83496 5.83341 8.89477 5.83341 9.99984C5.83341 11.1049 6.2724 12.1647 7.0538 12.9461C7.8352 13.7275 8.89501 14.1665 10.0001 14.1665C11.1052 14.1665 12.165 13.7275 12.9464 12.9461C13.7278 12.1647 14.1667 11.1049 14.1667 9.99984C14.1667 8.89477 13.7278 7.83496 12.9464 7.05356C12.165 6.27216 11.1052 5.83317 10.0001 5.83317Z"
                            fill="#0397CC"
                          />
                        </svg>
                        <svg
                          v-else
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M9.99996 16.6665C8.23185 16.6665 6.53616 15.9641 5.28591 14.7139C4.03567 13.4636 3.33329 11.7679 3.33329 9.99984C3.33329 8.23173 4.03567 6.53603 5.28591 5.28579C6.53616 4.03555 8.23185 3.33317 9.99996 3.33317C11.7681 3.33317 13.4638 4.03555 14.714 5.28579C15.9642 6.53603 16.6666 8.23173 16.6666 9.99984C16.6666 11.7679 15.9642 13.4636 14.714 14.7139C13.4638 15.9641 11.7681 16.6665 9.99996 16.6665ZM9.99996 1.6665C8.90561 1.6665 7.82198 1.88205 6.81093 2.30084C5.79988 2.71963 4.88122 3.33346 4.1074 4.10728C2.5446 5.67008 1.66663 7.7897 1.66663 9.99984C1.66663 12.21 2.5446 14.3296 4.1074 15.8924C4.88122 16.6662 5.79988 17.28 6.81093 17.6988C7.82198 18.1176 8.90561 18.3332 9.99996 18.3332C12.2101 18.3332 14.3297 17.4552 15.8925 15.8924C17.4553 14.3296 18.3333 12.21 18.3333 9.99984C18.3333 8.90549 18.1177 7.82185 17.699 6.81081C17.2802 5.79976 16.6663 4.8811 15.8925 4.10728C15.1187 3.33346 14.2 2.71963 13.189 2.30084C12.1779 1.88205 11.0943 1.6665 9.99996 1.6665Z"
                            fill="#B3AFB6"
                          />
                        </svg>
                      </div>

                      <div
                        @click="handleColorView('websiteColor')"
                        class="d-flex justify-content-center align-items-center w-100"
                        style="
                          min-height: 72px;
                          border-radius: 8px;
                          background: #fff;
                        "
                        :style="
                          selectedColorView == 'websiteColor'
                            ? 'border: 1px solid #0397CC;'
                            : ' border: 1px solid #d2d8e0;'
                        "
                      >
                        <div class="textContainer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                          >
                            <path
                              d="M42 24C42 33.9411 33.9411 42 24 42M42 24C42 14.0589 33.9411 6 24 6M42 24H6M24 42C14.0589 42 6 33.9411 6 24M24 42C24 42 32 36 32 24C32 12 24 6 24 6M24 42C24 42 16 36 16 24C16 12 24 6 24 6M6 24C6 14.0589 14.0589 6 24 6"
                              stroke="#1F1F28"
                              stroke-width="3"
                            />
                          </svg>
                        </div>
                      </div>

                      <p
                        class="text-center mt-2 px-2"
                        style="
                          color: #73738d;
                          text-align: center;
                          font-family: Poppins;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          letter-spacing: 0.28px;
                        "
                      >
                        Add Colors from website
                      </p>
                    </div>
                    <div
                      v-for="scheme in allSchemeList"
                      :key="scheme.id"
                      style="
                        min-width: 120px;
                        min-height: 120px;
                        position: relative;
                        cursor: pointer;
                      "
                    >
                      <div
                        class=""
                        style="position: absolute; right: 5px; top: 2px"
                      >
                        <svg
                          v-if="selectedSchemeColorId == scheme.id"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M10.0001 16.6665C8.23197 16.6665 6.53628 15.9641 5.28604 14.7139C4.03579 13.4636 3.33341 11.7679 3.33341 9.99984C3.33341 8.23173 4.03579 6.53603 5.28604 5.28579C6.53628 4.03555 8.23197 3.33317 10.0001 3.33317C11.7682 3.33317 13.4639 4.03555 14.7141 5.28579C15.9644 6.53603 16.6667 8.23173 16.6667 9.99984C16.6667 11.7679 15.9644 13.4636 14.7141 14.7139C13.4639 15.9641 11.7682 16.6665 10.0001 16.6665ZM10.0001 1.6665C8.90573 1.6665 7.8221 1.88205 6.81105 2.30084C5.80001 2.71963 4.88135 3.33346 4.10752 4.10728C2.54472 5.67008 1.66675 7.7897 1.66675 9.99984C1.66675 12.21 2.54472 14.3296 4.10752 15.8924C4.88135 16.6662 5.80001 17.28 6.81105 17.6988C7.8221 18.1176 8.90573 18.3332 10.0001 18.3332C12.2102 18.3332 14.3298 17.4552 15.8926 15.8924C17.4554 14.3296 18.3334 12.21 18.3334 9.99984C18.3334 8.90549 18.1179 7.82185 17.6991 6.81081C17.2803 5.79976 16.6665 4.8811 15.8926 4.10728C15.1188 3.33346 14.2002 2.71963 13.1891 2.30084C12.1781 1.88205 11.0944 1.6665 10.0001 1.6665ZM10.0001 5.83317C8.89501 5.83317 7.8352 6.27216 7.0538 7.05356C6.2724 7.83496 5.83341 8.89477 5.83341 9.99984C5.83341 11.1049 6.2724 12.1647 7.0538 12.9461C7.8352 13.7275 8.89501 14.1665 10.0001 14.1665C11.1052 14.1665 12.165 13.7275 12.9464 12.9461C13.7278 12.1647 14.1667 11.1049 14.1667 9.99984C14.1667 8.89477 13.7278 7.83496 12.9464 7.05356C12.165 6.27216 11.1052 5.83317 10.0001 5.83317Z"
                            fill="#0397CC"
                          />
                        </svg>
                        <svg
                          v-else
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M9.99996 16.6665C8.23185 16.6665 6.53616 15.9641 5.28591 14.7139C4.03567 13.4636 3.33329 11.7679 3.33329 9.99984C3.33329 8.23173 4.03567 6.53603 5.28591 5.28579C6.53616 4.03555 8.23185 3.33317 9.99996 3.33317C11.7681 3.33317 13.4638 4.03555 14.714 5.28579C15.9642 6.53603 16.6666 8.23173 16.6666 9.99984C16.6666 11.7679 15.9642 13.4636 14.714 14.7139C13.4638 15.9641 11.7681 16.6665 9.99996 16.6665ZM9.99996 1.6665C8.90561 1.6665 7.82198 1.88205 6.81093 2.30084C5.79988 2.71963 4.88122 3.33346 4.1074 4.10728C2.5446 5.67008 1.66663 7.7897 1.66663 9.99984C1.66663 12.21 2.5446 14.3296 4.1074 15.8924C4.88122 16.6662 5.79988 17.28 6.81093 17.6988C7.82198 18.1176 8.90561 18.3332 9.99996 18.3332C12.2101 18.3332 14.3297 17.4552 15.8925 15.8924C17.4553 14.3296 18.3333 12.21 18.3333 9.99984C18.3333 8.90549 18.1177 7.82185 17.699 6.81081C17.2802 5.79976 16.6663 4.8811 15.8925 4.10728C15.1187 3.33346 14.2 2.71963 13.189 2.30084C12.1779 1.88205 11.0943 1.6665 9.99996 1.6665Z"
                            fill="#B3AFB6"
                          />
                        </svg>
                      </div>

                      <div
                        @click="handleColorView('schemeColor', scheme)"
                        class="d-flex flex-column justify-content-center align-items-center w-100"
                        style="
                          min-height: 72px;
                          border-radius: 8px;
                          background: #fff;
                        "
                        :style="
                          selectedColorView == 'schemeColor' &&
                          selectedSchemeColorId == scheme.id
                            ? 'border: 1px solid #0397CC;'
                            : ' border: 1px solid #d2d8e0;'
                        "
                      >
                        <div class="textContainer">
                          <span>Aa</span>
                        </div>
                        <div class="d-flex justify-content-center mt-2">
                          <div
                            v-for="(color, index) in scheme.baseColors"
                            :key="index"
                          >
                            <div
                              class="baseColorContainer mr-1"
                              :style="getBaseColor(color)"
                            ></div>
                          </div>
                        </div>
                      </div>

                      <p
                        class="text-center mt-2 px-2"
                        style="
                          color: #73738d;
                          text-align: center;
                          font-family: Poppins;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          letter-spacing: 0.28px;
                        "
                      >
                        {{ scheme.name }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="mt-2 manualColors"
                    v-if="selectedColorView == 'schemeColor'"
                  >
                    <div class="d-flex justify-content-between mb-2">
                      <div class="">
                        <div class="ai-tool-heading" style="color: #535355">
                          Background
                        </div>
                        <div class="d-flex">
                          <input
                            v-model="QuizPayload.design.background_color"
                            type="color"
                            value="#FFFFFF"
                            class="colorInput mr-2"
                          />
                          <input
                            v-model="QuizPayload.design.background_color"
                            class="ai-tool-heading ai-color-input"
                            value="#FFFFFF"
                          />
                        </div>
                      </div>

                      <div class="">
                        <div class="ai-tool-heading" style="color: #535355">
                          Primary Color
                        </div>
                        <div class="d-flex">
                          <input
                            v-model="QuizPayload.design.primary_color"
                            type="color"
                            value="#193247"
                            class="colorInput mr-2"
                          />
                          <input
                            v-model="QuizPayload.design.primary_color"
                            class="ai-tool-heading ai-color-input"
                            value="#193247"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="d-flex justify-content-between mb-2"
                      style="color: #535355"
                    >
                      <div class="">
                        <div class="ai-tool-heading">Secondary Color</div>
                        <div class="d-flex">
                          <input
                            v-model="QuizPayload.design.secondary_color"
                            type="color"
                            value="#FFFFFF"
                            class="colorInput mr-2"
                          />
                          <input
                            v-model="QuizPayload.design.secondary_color"
                            class="ai-tool-heading ai-color-input"
                            value="#FFFFFF"
                          />
                        </div>
                      </div>

                      <div class="">
                        <div class="ai-tool-heading" style="color: #535355">
                          Text Color
                        </div>
                        <div class="d-flex">
                          <input
                            type="color"
                            v-model="QuizPayload.design.text_color"
                            value="#193247"
                            class="colorInput mr-2"
                          />
                          <input
                            v-model="QuizPayload.design.text_color"
                            class="ai-tool-heading ai-color-input"
                            value="#193247"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="d-flex justify-content-between mb-2"
                      style="color: #535355"
                    >
                      <div class="">
                        <div class="ai-tool-heading" style="color: #535355">
                          Button Text Color
                        </div>
                        <div class="d-flex">
                          <input
                            type="color"
                            v-model="QuizPayload.design.button_text_color"
                            value="#767575"
                            class="colorInput mr-2"
                          />
                          <input
                            v-model="QuizPayload.design.button_text_color"
                            class="ai-tool-heading ai-color-input"
                            value="#193247"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-2" v-if="selectedColorView == 'websiteColor'">
                    <p
                      style="
                        color: #535355;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
                      "
                    >
                      Website Link
                    </p>

                    <div class="d-flex align-items-center">
                      <input
                        type="text"
                        v-model="getWebsiteLink"
                        placeholder="Website Link (https)"
                        class="ColorTextInput form-control-sm input-border-style mr-2"
                        style="flex: 1; min-height: 40px"
                      />

                      <Button
                        @click="getWebsiteColors"
                        :disabled="websiteColorLoading"
                      >
                        <span v-if="!websiteColorLoading"> Apply</span>
                        <div
                          v-else
                          class="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </Button>
                    </div>

                    <div
                      class="mt-3"
                      v-if="websiteColorLoaded && getWebsiteLink != ''"
                    >
                      <div class="d-flex justify-content-between mb-2">
                        <div class="">
                          <div class="ai-tool-heading" style="color: #535355">
                            Background
                          </div>
                          <div class="d-flex">
                            <input
                              v-model="QuizPayload.design.background_color"
                              type="color"
                              value="#FFFFFF"
                              class="colorInput mr-2"
                            />
                            <input
                              v-model="QuizPayload.design.background_color"
                              class="ai-tool-heading ai-color-input"
                              value="#FFFFFF"
                            />
                          </div>
                        </div>

                        <div class="">
                          <div class="ai-tool-heading" style="color: #535355">
                            Primary Color
                          </div>
                          <div class="d-flex">
                            <input
                              v-model="QuizPayload.design.primary_color"
                              type="color"
                              value="#193247"
                              class="colorInput mr-2"
                            />
                            <input
                              v-model="QuizPayload.design.primary_color"
                              class="ai-tool-heading ai-color-input"
                              value="#193247"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        class="d-flex justify-content-between mb-2"
                        style="color: #535355"
                      >
                        <div class="">
                          <div class="ai-tool-heading">Secondary Color</div>
                          <div class="d-flex">
                            <input
                              v-model="QuizPayload.design.secondary_color"
                              type="color"
                              value="#FFFFFF"
                              class="colorInput mr-2"
                            />
                            <input
                              v-model="QuizPayload.design.secondary_color"
                              class="ai-tool-heading ai-color-input"
                              value="#FFFFFF"
                            />
                          </div>
                        </div>

                        <div class="">
                          <div class="ai-tool-heading" style="color: #535355">
                            Text Color
                          </div>
                          <div class="d-flex">
                            <input
                              type="color"
                              v-model="QuizPayload.design.text_color"
                              value="#193247"
                              class="colorInput mr-2"
                            />
                            <input
                              v-model="QuizPayload.design.text_color"
                              class="ai-tool-heading ai-color-input"
                              value="#193247"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="d-flex justify-content-between mb-2"
                        style="color: #535355"
                      >
                        <div class="">
                          <div class="ai-tool-heading" style="color: #535355">
                            Button Text Color
                          </div>
                          <div class="d-flex">
                            <input
                              type="color"
                              v-model="QuizPayload.design.button_text_color"
                              value="#767575"
                              class="colorInput mr-2"
                            />
                            <input
                              v-model="QuizPayload.design.button_text_color"
                              class="ai-tool-heading ai-color-input"
                              value="#193247"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </Collapse>
              <div
                class="mb-2 pb-3 pt-2"
                style="border-bottom: 0.8px solid #f1f1f4"
              >
                <div class="d-flex">
                  <div class="mr-2">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.1667 7.16667H16.6667V17.1667H17.5V18H14.1667V17.1667H15V14.6667H11.6667L10.4167 17.1667H11.6667V18H8.33333V17.1667H9.16667L14.1667 7.16667ZM15 8L12.0833 13.8333H15V8ZM4.16667 3H8.33333C9.25833 3 10 3.74167 10 4.66667V13.8333H7.5V9.66667H5V13.8333H2.5V4.66667C2.5 3.74167 3.24167 3 4.16667 3ZM5 4.66667V8H7.5V4.66667H5Z"
                        fill="#18191C"
                      />
                    </svg>
                  </div>
                  <div class="ai-tool-heading">Font</div>
                </div>
                <select
                  name=""
                  v-model="QuizPayload.design.font"
                  class="font-select mt-2"
                  id=""
                >
                  <option
                    v-for="font in getFontFamilyList"
                    :key="font.value"
                    :value="font.value"
                  >
                    {{ font.value }}
                  </option>
                </select>
              </div>

              <!-- image -->
              <Collapse id="image" class="mb-2">
                <template v-slot:title>
                  <span class="mr-2">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.8333 16.3333H4.16667V4.66667H15.8333M15.8333 3H4.16667C3.72464 3 3.30072 3.17559 2.98816 3.48816C2.67559 3.80072 2.5 4.22464 2.5 4.66667V16.3333C2.5 16.7754 2.67559 17.1993 2.98816 17.5118C3.30072 17.8244 3.72464 18 4.16667 18H15.8333C16.2754 18 16.6993 17.8244 17.0118 17.5118C17.3244 17.1993 17.5 16.7754 17.5 16.3333V4.66667C17.5 4.22464 17.3244 3.80072 17.0118 3.48816C16.6993 3.17559 16.2754 3 15.8333 3ZM11.6333 10.7417L9.34167 13.6917L7.70833 11.725L5.41667 14.6667H14.5833L11.6333 10.7417Z"
                        fill="#18191C"
                      />
                    </svg>
                  </span>
                  Image
                </template>

                <template v-slot:collapse>
                  <div class="my-2">
                    <span
                      style="
                        color: #73738d;
                        font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 19.6px */
                        letter-spacing: 0.56px;
                      "
                      >Note: Image should not be more then 10 MB</span
                    >
                  </div>
                  <div
                    v-if="selectedImageURL"
                    class="d-flex my-3"
                    style="gap: 10px; position: relative"
                  >
                    <div class="ai-upload-image">
                      <div class="ai-upload-image-cross" @click="clearImage">
                        x
                      </div>
                      <lazy-image
                        style="
                          width: 88px;
                          object-fit: cover;
                          border-radius: 6px;
                        "
                        :src="selectedImageURL"
                      ></lazy-image>
                    </div>
                  </div>
                  <div class="">
                    <UploadFile
                      @files="updatedFile"
                      accept="image/*"
                      name="Image"
                      :maxFileSize="10"
                    ></UploadFile>
                  </div>
                </template>
              </Collapse>
              <!-- Language -->
              <collapse id="language" class="mb-2">
                <template v-slot:title>
                  <span class="mr-2">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.725 13.0583L8.60831 10.9666L8.63331 10.9416C10.0833 9.32496 11.1166 7.46663 11.725 5.49996H14.1666V3.83329H8.33331V2.16663H6.66665V3.83329H0.833313V5.49996H10.1416C9.58331 7.09996 8.69998 8.62496 7.49998 9.95829C6.72498 9.09996 6.08331 8.15829 5.57498 7.16663H3.90831C4.51665 8.52496 5.34998 9.80829 6.39165 10.9666L2.14998 15.15L3.33331 16.3333L7.49998 12.1666L10.0916 14.7583L10.725 13.0583ZM15.4166 8.83329H13.75L9.99998 18.8333H11.6666L12.6 16.3333H16.5583L17.5 18.8333H19.1666L15.4166 8.83329ZM13.2333 14.6666L14.5833 11.0583L15.9333 14.6666H13.2333Z"
                        fill="#18191C"
                      />
                    </svg>
                  </span>
                  Language
                </template>
                <template v-slot:collapse>
                  <ul class="p-0 m-0">
                    <li
                      class="d-flex px-1 align-items-center py-2"
                      :class="[
                        QuizPayload.design.language == language.lang
                          ? 'design-active'
                          : '',
                      ]"
                      @click="QuizPayload.design.language = language.lang"
                      v-for="(language, index) in languages"
                      :key="index"
                    >
                      <span class="mr-2">
                        <lazy-image
                          style="height: 30px; width: 30px"
                          :src="language.flag"
                        ></lazy-image>
                      </span>
                      {{ language.langText }}
                    </li>
                  </ul>
                </template>
              </collapse>
              <!-- Currency -->
              <collapse id="currency" class="mb-2">
                <template v-slot:title>
                  <span class="mr-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.8335 12.5H7.50016C7.50016 13.4 8.64183 14.1667 10.0002 14.1667C11.3585 14.1667 12.5002 13.4 12.5002 12.5C12.5002 11.5833 11.6335 11.25 9.80016 10.8083C8.0335 10.3667 5.8335 9.81667 5.8335 7.5C5.8335 6.00833 7.0585 4.74167 8.75016 4.31667V2.5H11.2502V4.31667C12.9418 4.74167 14.1668 6.00833 14.1668 7.5H12.5002C12.5002 6.6 11.3585 5.83333 10.0002 5.83333C8.64183 5.83333 7.50016 6.6 7.50016 7.5C7.50016 8.41667 8.36683 8.75 10.2002 9.19167C11.9668 9.63333 14.1668 10.1833 14.1668 12.5C14.1668 13.9917 12.9418 15.2583 11.2502 15.6833V17.5H8.75016V15.6833C7.0585 15.2583 5.8335 13.9917 5.8335 12.5Z"
                        fill="#18191C"
                      ></path>
                    </svg>
                  </span>
                  Currency
                </template>
                <template v-slot:collapse>
                  <ul class="p-0 m-0">
                    <li
                      class="d-flex px-1 align-items-center py-2"
                      :class="[
                        QuizPayload.design.currency == currency.id
                          ? 'design-active'
                          : '',
                      ]"
                      @click="QuizPayload.design.currency = currency.id"
                      v-for="(currency, index) in currencies"
                      :key="index"
                    >
                      <span class="mr-2">
                        <lazy-image
                          style="height: 30px; width: 30px"
                          :src="currency.flag"
                        ></lazy-image>
                      </span>
                      {{ currency.country }} - {{ currency.sign }}
                    </li>
                  </ul>
                </template>
              </collapse>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 pl-0 col-md-7 py-3"
        :style="{ 'font-family': QuizPayload.design.font }"
      >
        <div
          class="card"
          style="
            border: 1px solid transparent;
            border-radius: 8px;
            height: 420px;
            overflow: auto;
          "
          :style="{ background: QuizPayload.design.background_color }"
        >
          <div class="card-body">
            <div class="row h-100">
              <div :class="[selectedImageURL ? 'col-6' : 'col-12']">
                <div class="d-flex h-100 justify-content-center flex-column">
                  <div class="">
                    <div
                      class="ai-preview-heading"
                      :style="{ color: QuizPayload.design.text_color }"
                    >
                      The main title text
                    </div>
                    <div
                      class="ai-preview-description py-3"
                      :style="{ color: QuizPayload.design.text_color }"
                    >
                      Take our quiz about skincare products and discover the
                      best products tailored specifically for your skin's unique
                      needs.
                    </div>
                    <button
                      :style="{
                        background: QuizPayload.design.primary_color,
                        color: QuizPayload.design.button_text_color,
                      }"
                      class="ai-preview-button"
                    >
                      Button
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-6" v-if="selectedImageURL">
                <lazy-image
                  ref="image"
                  style="object-fit: cover; border-radius: 6px"
                  class="w-100 h-100"
                  :src="selectedImageURL"
                ></lazy-image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 d-flex justify-content-end">
        <Button @click="$emit('click')"
          >Next Step
          <span class="mr-1"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M3.33334 9.16669V10.8334H13.3333L8.75001 15.4167L9.93334 16.6L16.5333 10L9.93334 3.40002L8.75001 4.58336L13.3333 9.16669H3.33334Z"
                fill="white"
              /></svg
          ></span>
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import fontFamilyList from "../../../customize/staticPages/fontFamilyList";
import LazyImage from "../../../../components/LazyImage.vue";
import Collapse from "./LayoutComponents/Collapse.vue";
import UploadFile from "./LayoutComponents/UploadFile.vue";
import Button from "../../Layout/Button.vue";
import axios from "axios";
export default {
  components: { Button, Collapse, LazyImage, UploadFile },
  props: { QuizPayload: Object },
  data() {
    return {
      imageUrl: this.QuizPayload.design.image
        ? URL.createObjectURL(this.QuizPayload.design.image)
        : "",
      languages: [],
      currencies: [],
      isLoadSchemeLoading: false,
      allSchemeList: [],
      selectedColorView: "schemeColor",
      websiteLink: "",
      defaultColors: {
        primary_color: "#D1D1D1",
        secondary_color: "#F8F9FA",
        background_color: "#FFFFFF",
        button_text_color: "#767575",
        text_color: "#000000",
      },
      websiteColors: null,
      websiteColorLoading: false,
      websiteColorLoaded: false,
      selectedSchemeColorId: null,
    };
  },
  computed: {
    getWebsiteLink: {
      get() {
        return this.websiteLink;
      },
      set(value) {
        this.websiteLink = value;
        this.$store.dispatch("formatUrl", value).then((formattedUrl) => {
          this.websiteLink = formattedUrl;
        });
      },
    },
    selectedImageURL() {
      return this.imageUrl;
    },
    getFontFamilyList() {
      return fontFamilyList;
    },
    textBlockFont() {
      return this.QuizPayload.design.font;
    },
  },
  methods: {
    isValidUrl(link) {
      try {
        new URL(link);
        return true;
      } catch (_) {
        return false;
      }
    },
    async getWebsiteColors() {
      this.websiteColorLoading = true;

      try {
        if (!this.isValidUrl(this.websiteLink)) {
          this.$toasted.show("Please provide a valid link", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          return;
        }

        const colors = await this.$store.dispatch("getWebsiteColorAction", {
          websiteLink: this.websiteLink,
        });

        if (colors && typeof colors === "object") {
          console.log("Colors", colors);

          this.QuizPayload.design.primary_color = colors.selectedOptionsBg;
          this.QuizPayload.design.secondary_color = colors.backButton;
          this.QuizPayload.design.background_color = colors.background;
          this.QuizPayload.design.button_text_color = colors.optionText;
          this.QuizPayload.design.text_color = colors.textColor;

          this.websiteColorLoaded = true;
        } else {
          this.$toasted.show(
            "Insufficient colors received. Please try again.",
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
        }
      } catch (error) {
        this.$toasted.show("Something went wrong. Please try again!", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      } finally {
        this.websiteColorLoading = false;
      }
    },
    getBaseColor(color) {
      if (!color) return `background:#000000;`;

      return `background:${color};`;
    },
    handleColorView(view, colors = null) {
      this.selectedColorView = view;
      if (view == "schemeColor" && colors) {
        this.websiteColorLoaded = false;
        this.selectedSchemeColorId = colors.id;
        this.QuizPayload.design.primary_color = colors.selectedOptionsBg;
        this.QuizPayload.design.secondary_color = colors.nonSelectedOptionsBg;
        this.QuizPayload.design.background_color = colors.background;
        this.QuizPayload.design.button_text_color = colors.selectedOptionText;
        this.QuizPayload.design.text_color = colors.textColor;
      } else {
        this.selectedSchemeColorId = null;
      }
    },
    async loadColorSchemes() {
      try {
        this.isLoadSchemeLoading = true;

        const response = await axios.get(`/color-schemes`);
        if (response.status == 200) {
          this.allSchemeList = response.data.data.map((scheme, index) => {
            return {
              ...scheme,
              id: `scheme-${index}-${Date.now()}`,
            };
          });

        this.handleColorView('schemeColor', this.allSchemeList[0])
        }
      } catch (error) {
      
        this.allSchemeList = [
          {
            id: 1266,
            quizId: 10846,
            status: "draft",
            name: "Scheme 1",
            baseColors: ["#F4F4F2", "#FFC107"],
            background: "#F4F4F2",
            textColor: "#333333",
            selectedOptionsBg: "#005A8D",
            nonSelectedOptionsBg: "#E0E1E2",
            optionText: "#575757",
            generalButtons: "#FFC107",
            backButton: "#CCCCCC",
            nextButton: "#F57C00",
            selectedOptionText: "#FFFFFF",
          },
          {
            id: 1267,
            quizId: 10846,
            status: "draft",
            name: "Scheme 2",
            baseColors: ["#000000", "#FF5722"],
            background: "#000000",
            textColor: "#FFFFFF",
            selectedOptionsBg: "#007BFF",
            nonSelectedOptionsBg: "#333333",
            optionText: "#D0D0D0",
            generalButtons: "#FF5722",
            backButton: "#555555",
            nextButton: "#CDDC39",
            selectedOptionText: "#FFEB3B",
          },
          {
            id: 1268,
            quizId: 10846,
            status: "draft",
            name: "Scheme 3",
            baseColors: ["#FADADD", "#9C27B0"],
            background: "#FADADD",
            textColor: "#003366",
            selectedOptionsBg: "#FF6B6B",
            nonSelectedOptionsBg: "#FFE4E1",
            optionText: "#001F3F",
            generalButtons: "#9C27B0",
            backButton: "#8E4585",
            nextButton: "#E040FB",
            selectedOptionText: "#FFFFFF",
          },
          {
            id: 1269,
            quizId: 10846,
            status: "draft",
            name: "Scheme 4",
            baseColors: ["#E0F7FA", "#00BCD4"],
            background: "#E0F7FA",
            textColor: "#1A237E",
            selectedOptionsBg: "#0097A7",
            nonSelectedOptionsBg: "#B3E5FC",
            optionText: "#283593",
            generalButtons: "#00BCD4",
            backButton: "#80D8FF",
            nextButton: "#3F51B5",
            selectedOptionText: "#FFFFFF",
          },
        ];

        this.handleColorView('schemeColor', this.allSchemeList[0])
        throw error;
      } finally {
        this.isLoadSchemeLoading = false;
      }
    },
    async getFontList() {
      try {
        const response = await axios.get(`/fonts/list`);
        this.$store.commit("ADD_EDITOR_FONT_LIST", response.data.data);
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
          throw error;
        }
      }
    },
    async getCurrencyLanguage() {
      await axios
        .get("/quiz/design-customization")
        .then((response) => {
          this.currencies = response.data.data.currencies;
          this.languages = response.data.data.languages;
        })
        .catch([]);
    },
    clearImage() {
      this.QuizPayload.design.image = "";
      this.imageUrl = null;
    },
    updatedFile(files) {
      if (files[0]) {
        const isValid = this.isValidImageFormat(files[0].type);

        if (!isValid) {
          this.$toasted.show(
            "Invalid file format. Please select a JPEG, JPG, PNG, or GIF file.",
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );

          return;
        }
        let th = this;
        th.QuizPayload.design.image = files[0];
        const reader = new FileReader();
        reader.onload = function (e) {
          th.imageUrl = null;
          th.$nextTick(() => {
            th.imageUrl = e.target.result;

            // this.imageUrl = e.target.result;
          });
          // th.imageUrl =e.target.result;
        };
        reader.readAsDataURL(files[0]);
      }
    },
    isValidImageFormat(fileType) {
      const validTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
      return validTypes.includes(fileType);
    },
  },
  mounted() {
    this.loadColorSchemes();
    this.getCurrencyLanguage();
  },
  watch: {
    getFontFamilyList: {
      immediate: true,
      handler() {
        if (this.getFontFamilyList.length < 1) {
          this.getFontList();
        }
      },
    },
    textBlockFont(newVal, oldVal) {
      if (oldVal && newVal) {
        this.$store.dispatch("updateFontFamily", {
          oldFontFamily: oldVal,
          newFontFamily: newVal,
        });
        this.$store.dispatch("loadFontsFromTracker");
      }
    },
  },
};
</script>
<style scoped>
.design-active {
  background: rgba(77, 27, 126, 0.3);
  border-radius: 4px;
  cursor: pointer;
}
ul li:not(.design-active):hover {
  background: #f5f6f6;
  border-radius: 4px;
  cursor: pointer;
}

.ai-preview-heading {
  color: #193247;

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  letter-spacing: 0.4px;
}
.ai-preview-description {
  color: #687b8b;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}
.ai-preview-button {
  padding: 8px 16px;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
  border-radius: 6.822px;
  background: #c8c7c6;
  border: none;
}

.ai-upload-image-cross {
  position: absolute;
  right: -2px;
  top: -5px;
  background: red;
  border-radius: 50%;
  padding: 7px;
  /* line-height: 0.8; */
  height: 13px;
  width: 10px;
  font-size: 12px;
  display: none;
  justify-content: center;
  align-items: center;
  color: wheat;
  font-weight: 600;
  cursor: pointer;
}
.ai-upload-image {
  position: relative;
}
.ai-upload-image:hover .ai-upload-image-cross {
  display: flex;
}
input[type="color"] {
  width: 25px;
  height: 23px;
  border-radius: 50%;
  overflow: hidden;
  border: none;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 23px;
  padding: 0;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  border: none;
  width: 25px;
  height: 23px;
  border-radius: 50%;
  padding: 0;
}

.ai-color-input {
  width: 50%;
  color: #535355;
  border: none;
}

.ai-color-input:focus-visible {
  border: none;
  outline: none;
}

.colorInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 30px !important;
  height: 30px !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 2px !important;
  margin-right: 5px;
}
.colorInput::-webkit-color-swatch {
  border: 2px solid #cfcece;
}
.colorInput::-moz-color-swatch {
  border: 2px solid #cfcece;
}

.font-select:active,
.font-select:focus-visible {
  outline: 1px solid rgba(77, 27, 126, 1);
}
.font-select {
  width: 100%;
  border-radius: 6px;
  border: 0.8px solid #d2d8e0;
  background: #fff;
  padding: 4px;
  color: #535355;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.56px;
}

.ai-tool-heading {
  color: #18191c;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
}

.quizell-step3-sidebar-title {
  color: #1f1f28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.64px;
}

.quizell-step3-sidebar-description {
  color: #73738d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.baseColorContainer {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}
</style>
